/* src/styles/InvoiceDetails.css */
.invoice-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoice-logo img {
  max-width: 150px;
}

.invoice-info p {
  margin: 0;
  padding: 5px 0;
}

.print-button {
  margin-top: 20px;
}
.logo {
  float: right;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.userDetails {
  margin-bottom: 20px;
}

.customerDetails {
  color: blue;
}
