/* src/components/LandingPage.css */
.jumbotron {
  background-color: #f8f9fa;
  padding: 3rem 2rem;
}

.jumbotron .lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.jumbotron img {
  border-radius: 10px;
  max-width: 100%;
}
